exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leave-a-review-offer-js": () => import("./../../../src/pages/leave-a-review-offer.js" /* webpackChunkName: "component---src-pages-leave-a-review-offer-js" */),
  "component---src-pages-locations-colorado-springs-dispensary-js": () => import("./../../../src/pages/locations/colorado-springs-dispensary.js" /* webpackChunkName: "component---src-pages-locations-colorado-springs-dispensary-js" */),
  "component---src-pages-locations-denver-dispensary-js": () => import("./../../../src/pages/locations/denver-dispensary.js" /* webpackChunkName: "component---src-pages-locations-denver-dispensary-js" */),
  "component---src-pages-locations-index-js": () => import("./../../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-locations-pueblo-west-dispensary-js": () => import("./../../../src/pages/locations/pueblo-west-dispensary.js" /* webpackChunkName: "component---src-pages-locations-pueblo-west-dispensary-js" */),
  "component---src-pages-menu-index-js": () => import("./../../../src/pages/menu/index.js" /* webpackChunkName: "component---src-pages-menu-index-js" */),
  "component---src-pages-menu-pueblo-west-js": () => import("./../../../src/pages/menu/pueblo-west.js" /* webpackChunkName: "component---src-pages-menu-pueblo-west-js" */)
}

